import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import CustomersPage from './components/customers/CustomersPage';
import RestaurantDashboard from './components/restaurant/RestaurantDashboard';
import SignupPage from './components/Auth/Signup/SignupPage';
import LoginPage from './components/Auth/Login/LoginPage';
import HomePage from './components/Home/HomePage';
import { API_URL } from './EnvironmentVariables';
import BdWizard from './components/customers/BdWizard';
import NotFoundPage from './components/NotFoundPage';
import ResetPasswordPage from './components/Auth/ResetPassword/ResetPasswordPage';
import AboutusPage from './components/AboutUs/AboutusPage';
import BdWizardDemo from './components/customers/BdWizardDemo';
import DemoRestaurantDashboard from './components/restaurant/DemoRestaurantDashboard';
import PricingPage from './components/Pricing/PricingPage';
import WaitlistForCustoemrs from './components/customers/WaitlistForCustoemrs';
import CancelReservation from './components/customers/CancelReservation';
import DecodingAcceptableWaitTimes from './components/Blogs/DecodingAcceptableWaitTimes';
import IntroducingPlaceinq from './components/Blogs/IntroducingPlaceinq';
import ManagingRestaurantWaitlistsDuringPeakHours from './components/Blogs/ManagingRestaurantWaitlistsDuringPeakHours';
import Blogs from './components/Blogs/Blogs';
import BoostingRestaurantProfitabilitywithPlaceinQ from './components/Blogs/BoostingRestaurantProfitabilitywithPlaceinQ';
import ContactUsPage from './components/ContactUs/ContactUsPage';
import NewLogin from './components/Auth/Login/NewLogin';

function App() {
  return (
    <Router>
      {/* <div className='App-container'> */}

        <div className="App">

          <Routes>

            <Route exact path="/" element={<HomePage/>} />

            <Route exact path="/signup" element={<NewLogin/>} />

            <Route exact path="/login" element={<NewLogin/>} />

            <Route exact path="/demo/customers" element={<BdWizardDemo/>} />

            <Route exact path="/dashboard" element={<RestaurantDashboard/>} />
            
            <Route exact path="/demo/dashboard" element={<DemoRestaurantDashboard/>} />

            <Route exact path="/about-us" element={<AboutusPage/>} />

            <Route exact path="/contact-us" element={<ContactUsPage/>} />

            <Route exact path="/pricing" element={<PricingPage/>} />

            <Route exact path="/:restaurantName" element={<CustomersPage/>} />

            <Route exact path="/:restaurantName/:customerID" element={<WaitlistForCustoemrs/>} />
            
            <Route exact path="/:restaurantName/:customerID/cancel" element={<CancelReservation/>} />

            <Route exact path="/password_reset/:token" element={<ResetPasswordPage/>} />
            
            <Route exact path="/blogs" element={<Blogs/>} />
            <Route exact path="/blogs/decoding-acceptable-wait-times" element={<DecodingAcceptableWaitTimes/>} />
            <Route exact path="/blogs/introducing-placeinq" element={<IntroducingPlaceinq/>} />
            <Route exact path="/blogs/managing-restaurant-waitlists-during-peak-hours" element={<ManagingRestaurantWaitlistsDuringPeakHours/>} />
            <Route exact path="/blogs/boosting-restaurant-profitability-with-placeinq" element={<BoostingRestaurantProfitabilitywithPlaceinQ/>} />

            <Route path="/not-found" element={<NotFoundPage />} />

            <Route path="*" element={<NotFoundPage />} />

          </Routes>

        </div>
      {/* </div> */}

    </Router>
  );
}

export default App;

let cachedData = null;

const GetUser = async () => { 
    if (cachedData !== null) {
        return cachedData;
    }

    const token = localStorage.getItem('token');
    if (!token) {
        return null;
    }

    let return_obj = null;
    const url = `${API_URL}/api/me/`;
    await fetch(url, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(resp => {
      if(!resp.ok){
        return null;
      }
      return resp.json();
    })
    .then(data => {
      if (data) {
          return_obj = data;
          cachedData = return_obj;
      }
    })
    .catch(() => {
      return null;
    });
    
    return return_obj;
}

const clearCache = () => {
  cachedData = null;
};

export { GetUser, clearCache };