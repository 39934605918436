import React,{useState, useEffect} from 'react'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
// import { useStateValue } from '../StateManager/StateProvider';
import { API_URL } from '../../../EnvironmentVariables';
import Swal from 'sweetalert2';
import { GetUser } from '../../../App';

import './NewLogin.css'

const NewLogin = () => {
    const history = useNavigate();
    const location = useLocation();

    const [email,setEmail] = useState('');
    const [signupActive,setSignupActive] = useState(false);

    const [forgotpass_email,setForgotpass_email] = useState('');
    const [password,setPassword] = useState('');
    // const [Massage, setMassage] = useState(location?.state?.massage ? location.state.massage : '');
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [message, setMessage] = useState('');

    
    const [signupEmail, setSignupEmail] = useState('');
    const [signupPassword, setSignupPassword] = useState('');
    const [phonenumber, setPhonenumber] = useState('');
    const [shopName, setShopName] = useState('');

    let status = null;

    const container = document.getElementById('Login-container');
    const registerBtn = document.getElementById('register');
    const loginBtn = document.getElementById('login');

    if(registerBtn){
        registerBtn.addEventListener('click', () => {
            container.classList.add("active");
        });
    }

    if(loginBtn){
        loginBtn.addEventListener('click', () => {
            container.classList.remove("active");
        });        
    }

    useEffect( () => {
        if(window.location.pathname === "/signup"){
            const temp_container = document.getElementById('Login-container');
            temp_container.classList.add("active");
            setSignupActive(true);
        }
        else if(window.location.pathname === "/login"){
            const temp_container = document.getElementById('Login-container');
            temp_container.classList.add("active");
            temp_container.classList.remove("active");
            setSignupActive(true);
        }

        let uid;
        const fetchUser = async () => {
            const fetchedUser = await GetUser();

            if (fetchedUser !== null) 
                history('/dashboard');
        };
        fetchUser();

    }, []);

    const handleResponse = async (res, dashbaord) => {
        if(!res.token || res.token === undefined){
            Swal.fire({
                icon: 'error',
                title: 'Login Failed',
                text: 'Wrong email address or password'
              });
        }
        else {
            setMessage('');
            localStorage.setItem('token', res.token);
            if(dashbaord)
                history("/dashboard")
            else
                history("/customers")
        }
    }

    const signIn = (event = null, email, password, dashboard=true) => {
        if (event) {
            event.preventDefault();
        }
        const url = `${API_URL}/api/token/`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'email' : email,
                'password':  password
            })
        })
        .then(resp => resp.json())
        .then(res => handleResponse(res, dashboard))
        .catch(errors => console.log(errors));
    }

    const openForgot = () => {
        setShowForgotPasswordModal(true)
    };

    const closeForgot = () => {
        setShowForgotPasswordModal(false)
    };

    const SendRecoveryEmail = (event, _email) => {
        event.preventDefault();
        const url = `${API_URL}/api/password_reset/`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'email' : _email,
            })
        })
        .then(resp => resp.json())
        .then(res => {
            if(res.status !== "OK"){
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to send email'
                  });            
            }else{
                Swal.fire({
                    icon: 'success',
                    title: 'Recovery email sent successfully',
                    text: 'Please check your email inbox for instructions to set a new password.'
                  });  
                closeForgot();
                  setForgotpass_email('');
            }
        })
        .catch(errors => console.log(errors));
    }

    const signup = (event, email, password, phonenumber, address, shop_name) => {
        event.preventDefault();

        const data = new FormData();
        data.append('phone_number', phonenumber);
        data.append('password', password);
        data.append('email', email);
        data.append('restaurant_address', address);
        data.append('restaurant_name', shop_name);
        
        // if (selectedFile) {
        //     data.append('restaurant_logo', selectedFile);
        // }

        const url = `${API_URL}/api/register/`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'phone_number': phonenumber,
                'password' : password,
                'email' : email,
                'restaurant_address': address,
                'restaurant_name': shop_name
            })
            // body: data,
        })
        .then(response => {
            status = response.status;
            if (response.status === 201) {
                success_message();
                signIn(null, email, password);
                history("/dashboard");

                return;
            }
            else{
                return response.json();
            }
        }
        )
        .then(resp => {
                if (status !== 201){
                    setMessage(resp);
                    fail_message();
                }
            }
    )
    }

    const success_message = () =>{
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });
          Toast.fire({
            icon: "success",
            title: "Sign up successful"
          });
    }

    const fail_message = () =>{
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });
          Toast.fire({
            icon: "error",
            title: "Sign up failed",
          });
    }

    return(
        <div>

        <div className='hidden bg-[#eef1fa] md:flex flex-col items-center justify-center font-main h-screen'>
            <div className="Login-container" id="Login-container">

                <div className="login-form-container left-0 w-1/2 z-[1] opacity-0 sign-up">
                    <form className='bg-white flex flex-col items-center justify-center py-0 px-10 h-full'>
                        <h1 className='font-bold mb-4 text-xl'>Create Account</h1>

                        <input 
                            type='email' 
                            placeholder='Email'
                            className='placeinq-login-input'
                            value={signupEmail} 
                            required={true}
                            onChange={e => setSignupEmail(e.target.value)}
                        />
                        <h6 className='mr-auto text-xs text-orange-700'>{message.email?message.email[0]:''}</h6>

                        <input 
                            type='text'
                            placeholder='Phone number'
                            className='placeinq-login-input'
                            minLength={6}
                            required={true}
                            value={phonenumber} 
                            onChange={e => setPhonenumber(e.target.value)}
                        />
                        <h6 className='mr-auto text-xs text-orange-700'>{message.phone_number?message.phone_number:''}</h6>


                        <input
                            type='text' 
                            placeholder='Business name'
                            className='placeinq-login-input'
                            minLength={6}
                            required={true}
                            value={shopName} 
                            onChange={e => setShopName(e.target.value)}
                        />  
                        <h6 className='mr-auto text-xs text-orange-700'>{message.restaurant_name?message.restaurant_name:''}</h6>

                        <input
                            type='password'
                            placeholder='Password'
                            className='placeinq-login-input'
                            minLength={8}
                            required={true}
                            value={signupPassword} 
                            onChange={e => setSignupPassword(e.target.value)}
                        />
                        {(signupPassword.length > 7 || signupPassword.length === 0) ? null : <span className='mr-auto text-xs text-orange-700'>Password must be at least 8 characters long <br/></span>}


                        {/* <label className='no-asterisk'>Restaurant logo</label>
                        <input 
                            type="file" 
                            onChange={handleFileChange} 
                        /> */}
                        <p className="text-xs text-left mt-2">
                            After signing-up you agree to PlaceinQ's conditions of use and Privacy Notice.
                        </p>
                        <button 
                            disabled={!(shopName && phonenumber && signupEmail && signupPassword && (signupPassword.length > 7) )}
                            type='submit' 
                            className="placeinq-button mt-2 w-3/5" 
                            onClick={(event) =>  signup(event, signupEmail, signupPassword, phonenumber, 'address', shopName) }>
                                Sign Up
                        </button>
                    </form>
                </div>

                <div className="login-form-container left-0 w-1/2 z-[2] sign-in">
                    <form className='bg-white flex flex-col items-center justify-center py-0 px-10 h-full'>

                        {showForgotPasswordModal && 
                        <>
                        <h1 className='font-bold mb-4 text-xl'>Recover password</h1>

                            <input 
                                placeholder="example@your-email.com"
                                type='text' 
                                className='placeinq-login-input'
                                value={forgotpass_email} 
                                onChange={e => setForgotpass_email(e.target.value)}
                            />



                            <button
                                disabled={!(forgotpass_email)} 
                                className='placeinq-button w-4/5 mt-2'
                                type='submit' 
                                onClick={(event) => SendRecoveryEmail(event, forgotpass_email)}>Send me recovery email
                            </button>

                            <p onClick={closeForgot} className='cursor-pointer mr-auto mt-5 text-sm hover:text-placeinq border border-placeinq rounded-md py-2 px-1'>&lt; Sign In</p>


                            </>
                        }
                        {!showForgotPasswordModal &&    
                        <>      
                        <h1 className='font-bold mb-4 text-xl'>Sign In</h1>
            
                        <input 
                                placeholder="Email"
                                type='text' 
                                className='placeinq-login-input'
                                value={email} 
                                onChange={e => setEmail(e.target.value)}
                            />

                        <input 
                                placeholder="Password"
                                className='placeinq-login-input'
                                type='password' 
                                value={password} 
                                onChange={e => setPassword(e.target.value)}
                            />
                        
                        <button 
                            className='placeinq-button mt-2 w-3/5'
                            disabled={!(email && password)} 
                            type='submit'
                            onClick={(event) => signIn(event, email, password, true)}>Sign In
                        </button>
                        <p onClick={openForgot} className='cursor-pointer mr-auto mt-4 text-sm hover:text-placeinq'>Forgot Your Password?</p>

                        </>
                        }


                    </form>
                </div>

                <div className="login-toggle-container">
                    <div className="login-toggle">


                        <div className="login-toggle-panel login-toggle-left">
                            <div className="cursor-pointer mb-5">
                                <a href="/">
                                    <img className="h-16" src="/new-logo-white.png" alt="Logo" />
                                </a>
                            </div>

                            <p className='text-sm my-4'>Let's go to your dashboard</p>
                            <button className='w-[100px] py-2 px-3.5 border text-white border-solid border-white rounded-xl text-base font-main' id="login">Sign In</button>
                        </div>
                        <div className="login-toggle-panel login-toggle-right">
                            <div className="cursor-pointer mb-5">
                                <a href="/">
                                    <img className="h-16" src="/new-logo-white.png" alt="Logo" />
                                </a>
                            </div>

                            <h1>Hi!</h1>
                            <p className='text-sm my-4'>Start creating your waitlist</p>
                            <button className='w-[100px] py-2 px-3.5 border text-white border-solid border-white rounded-xl text-base font-main' id="register">Sign Up</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="flex flex-col md:hidden items-center justify-center h-screen">
            <div className="cursor-pointer mb-5">
                <a href="/">
                    <img className="h-16" src="/new-logo.png" alt="Logo" />
                </a>
            </div>
            <div className="w-4/5 ">

                {!signupActive &&
                <form className='bg-white flex flex-col items-center justify-center py-4 px-10 h-full border rounded-3xl shadow-signup'>

                    {showForgotPasswordModal && 
                    <>
                    <h1 className='font-bold mb-4 text-xl'>Recover password</h1>

                        <input 
                            placeholder="example@your-email.com"
                            type='text' 
                            className='placeinq-login-input'
                            value={forgotpass_email} 
                            onChange={e => setForgotpass_email(e.target.value)}
                        />



                        <button
                            disabled={!(forgotpass_email)} 
                            className='placeinq-button w-4/5 mt-2'
                            type='submit' 
                            onClick={(event) => SendRecoveryEmail(event, forgotpass_email)}>Send me recovery email
                        </button>

                        <p onClick={closeForgot} className='cursor-pointer mr-auto mt-5 text-sm hover:text-placeinq border border-placeinq rounded-md py-2 px-1'>&lt; Sign In</p>


                        </>
                    }
                    {!showForgotPasswordModal &&    
                    <>      
                    <h1 className='font-bold mb-4 text-xl'>Sign In</h1>
        
                    <input 
                            placeholder="Email"
                            type='text' 
                            className='placeinq-login-input'
                            value={email} 
                            onChange={e => setEmail(e.target.value)}
                        />

                    <input 
                            placeholder="Password"
                            className='placeinq-login-input'
                            type='password' 
                            value={password} 
                            onChange={e => setPassword(e.target.value)}
                        />
                    
                    <button 
                        className='placeinq-button mt-2 w-3/5'
                        disabled={!(email && password)} 
                        type='submit'
                        onClick={(event) => signIn(event, email, password, true)}>Sign In
                    </button>
                    <div className='mt-4 flex justify-between w-full'>
                        <p onClick={openForgot} className='cursor-pointer mr-auto text-sm hover:text-placeinq'>Forgot Your Password?</p>
                        <a href='/signup' className='cursor-pointer ml-auto text-sm hover:text-placeinq'>Create account</a>
                    </div>

                    </>
                    }


                </form>
                }
                {signupActive &&
                    <form className='bg-white flex flex-col items-center justify-center py-4 px-10 h-full border rounded-3xl shadow-signup'>
                        <h1 className='font-bold mb-4 text-xl'>Create Account</h1>

                        <input 
                            type='email' 
                            placeholder='Email'
                            className='placeinq-login-input'
                            value={signupEmail} 
                            required={true}
                            onChange={e => setSignupEmail(e.target.value)}
                        />
                        <h6 className='mr-auto text-xs text-orange-700'>{message.email?message.email[0]:''}</h6>

                        <input 
                            type='text'
                            placeholder='Phone number'
                            className='placeinq-login-input'
                            minLength={6}
                            required={true}
                            value={phonenumber} 
                            onChange={e => setPhonenumber(e.target.value)}
                        />
                        <h6 className='mr-auto text-xs text-orange-700'>{message.phone_number?message.phone_number:''}</h6>


                        <input
                            type='text' 
                            placeholder='Business name'
                            className='placeinq-login-input'
                            minLength={6}
                            required={true}
                            value={shopName} 
                            onChange={e => setShopName(e.target.value)}
                        />  
                        <h6 className='mr-auto text-xs text-orange-700'>{message.restaurant_name?message.restaurant_name:''}</h6>

                        <input
                            type='password'
                            placeholder='Password'
                            className='placeinq-login-input'
                            minLength={8}
                            required={true}
                            value={signupPassword} 
                            onChange={e => setSignupPassword(e.target.value)}
                        />
                        {(signupPassword.length > 7 || signupPassword.length === 0) ? null : <span className='mr-auto text-xs text-orange-700'>Password must be at least 8 characters long <br/></span>}

                        <p className="text-xs text-left mt-2">
                            After signing-up you agree to PlaceinQ's conditions of use and Privacy Notice.
                        </p>
                        <button 
                            disabled={!(phonenumber && signupEmail && shopName && signupPassword && (signupPassword.length > 7) )}
                            type='submit' 
                            className="placeinq-button mt-2 w-3/5" 
                            onClick={(event) =>  signup(event, signupEmail, signupPassword, phonenumber, 'address', shopName) }>
                                Sign Up
                        </button>
                        <div className='mt-5 flex justify-between w-full'>
                            <a href='/login' className='cursor-pointer mr-auto text-sm hover:text-placeinq'>Already have an account</a>
                        </div>
                    </form>
                }
            </div>

        </div>

        </div>


    )
}

export default NewLogin;